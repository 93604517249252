<template>
  <div class="page">
    <div class="main">
      <div class="title">添加新卡</div>
      <el-select
        v-model="expert_id"
        placeholder="卡片所属老师"
        size="mini"
        clearable
        @change="createList"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <el-option
          v-for="item in ExpertList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-row class="check" type="flex" align="middle">
        <el-col :span="5">
          <div>
            设置卡片数量
            <el-input
              style="width: 100px; margin-left: 5px"
              v-model="num"
              @change="createList"
              size="mini"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div>
            <el-checkbox v-model="is_str"></el-checkbox>
            添加前缀
            <el-input
              style="width: 140px; margin-left: 5px"
              v-model="str"
              maxlength="4"
              @change="createList"
              placeholder="最多可输入4位字母"
              size="mini"
            ></el-input>
          </div>
        </el-col>
        <!-- <el-col :span="2" :offset="1">
          <el-select
            v-model="card_type"
            placeholder="卡片类型"
            size="mini"
            @change="createList"
            style="margin-top: 20px; margin-bottom: 20px"
          >
            <el-option
              v-for="item in cardTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="3" :offset="1">
          <el-checkbox v-model="year" @change="createList"
            >添加年份</el-checkbox
          >
        </el-col>
        <el-col :span="2">
          <el-checkbox v-model="rand" @change="createList"
            >随机生成</el-checkbox
          >
        </el-col>
        <!-- <el-col :span="2">
          <el-checkbox v-model="is_vip">vip</el-checkbox>
        </el-col> -->
        <el-col :span="2">
          <el-button @click="createCard" size="mini" type="primary"
            >确认发卡</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="tableData" border style="width: 100%; margin-top: 30px">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="card_num" label="卡号"></el-table-column>
        <el-table-column prop="password" label="密码"></el-table-column>
        <el-table-column prop="expert_id" label="所属导师">
          <template scope="scope">
            <div v-for="(item, key) in ExpertList" :key="key">
              <div v-if="item.id == scope.row.parent_id">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="编辑" width="300">
          <template scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.card_num"
              placement="top"
            >
              <el-button type="text" @click="copyText(scope.row)"
                >复制卡号密码</el-button
              >
            </el-tooltip>
          </template>
        </el-table-column>
        <!--                <el-table-column prop="brief" label="备注"></el-table-column>-->
      </el-table>
    </div>
  </div>
</template>

<script>
//time: 2021/1/27
export default {
  name: "CreateCard",
  data() {
    return {
      ExpertInfo: [],
      expert_id: "",
      ExpertList: [],
      num: "",
      str: "",
      is_str: "",
      year: true,
      rand: false,
      card_type: 3,
      tableData: [],
      cardTypeList: [
        {
          id: 0,
          name: "普通用户",
        },
        {
          id: 5,
          name: "自建用户",
        },
        {
          id: 3,
          name: "志愿审核用户",
        },
        // {
        //   id: 2,
        //   name: "测评卡用户",
        // },
      ],
      is_vip: true,
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
  },
  methods: {
    getList() {
      const data = {
        parent_id: this.ExpertInfo.id,
      };
      this.$fecth.post("expert/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.ExpertList = data.lists;
          this.ExpertList.push({
            name: this.ExpertInfo.name,
            id: this.ExpertInfo.id,
          });
        } else {
          this.showTips(0, msg);
        }
      });
    },
    createList() {
      if (!this.expert_id) {
        this.expert_id = this.ExpertInfo.id;
      }
      this.tableData = [];
      let str = "";
      if (this.is_str) {
        str = this.str;
      }
      if (this.year) {
        str = str + "2021";
      }

      if (!this.expert_id) {
        this.expert_id = this.ExpertInfo.id;
      }

      if (this.expert_id && this.num > 0) {
        let st = "";
        for (let i = 0; i < this.num; i++) {
          if (this.rand) {
            st =
              str + ("000000" + Math.floor(Math.random() * 999999)).slice(-6);
          } else {
            st = str;
          }

          this.tableData.push({
            card_num: st + 1 + i,
            password: ("000000" + Math.floor(Math.random() * 999999)).slice(-6),
            parent_id: this.expert_id,
            brief: "",
            vip: this.is_vip,
            type: this.card_type,
          });
        }
      }
    },
    createCard() {
      if (this.ExpertInfo.status == 1) {
        let data = {
          data: this.tableData,
        };
        this.$fecth.post("Vip/createCard", data).then((res) => {
          const { code, msg } = res;
          if (code == 200) {
            this.showTips(1, "生成成功");
            this.tableData = [];
          } else {
            this.showTips(0, msg);
          }
        });
      } else {
        this.showTips(0, "暂无发卡权限");
      }
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
    copyText(row) {
      let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value = "账号：" + row.card + "密码：" + row.password; //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      alert("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.check {
  font-size: 14px;
}
</style>